import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dropdown, ListGroup } from "react-bootstrap";
import { FaYoutube, FaInstagram, FaCube } from "react-icons/fa";
import { MdArrowOutward } from "react-icons/md";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet"; 
import "./SingleProduct.css";
import { SingleProductDetail } from "../../utils/constants";

const SingleProduct = () => {
  const navigate = useNavigate();
   // Add a ref for the image grid container
   const imageGridRef = useRef(null);
  const { productName } = useParams();
  const selectedProduct = SingleProductDetail.find((item) => item.name.toLowerCase() === productName);

  const [activeButton, setActiveButton] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showDropdown, setShowDropdown] = useState(false);

  const gridRef = useRef(null);

  const handleButtonClick = (index) => {
    setActiveButton((prevActiveButton) => (prevActiveButton === index ? null : index));
  };

  const handleImageClick = (index) => {
    setClickedIndex((prevClickedIndex) => (prevClickedIndex === index ? null : index));
   // Scroll image grid to top
   if (imageGridRef.current) {
    imageGridRef.current.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  
  // Optional: Also scroll window to top
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (gridRef.current && !gridRef.current.contains(event.target)) {
        setClickedIndex(null);
      }
      window.scrollTo(0, 0);
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [gridRef]);

  const isLastRow = (index) => {
    return (
      index >=
      selectedProduct.images.length -
      (selectedProduct.images.length % 3 === 0
        ? 3
        : selectedProduct.images.length % 3)
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="container main-container">
        {/* 🛠 Dynamic Meta Tags for SEO */}
        <Helmet>
        <title>{selectedProduct.Productname} | {selectedProduct.metatittles}</title>
        <meta name="description" content={selectedProduct.metadescription} />
        <meta property="og:title" content={selectedProduct.metatittles}  />
        <meta property="og:description" content={selectedProduct.metadescription} />
      </Helmet>
      <div className="row">
        <div className="col-12">
          <button onClick={() => navigate(-1)} className="back-button">
            <span className="arrow">&larr; Back</span>
          </button>

          {isMobile && (
            <div className="mobile-controls">
              <div className="col-12 single-head mb-3 px-3">
                <h3>{selectedProduct.Productname.charAt(0).toUpperCase() + selectedProduct.Productname.slice(1)}</h3>
              </div>
              <div className="mobile-row">
                <Dropdown
                  show={showDropdown}
                  onToggle={(isOpen) => setShowDropdown(isOpen)}
                  className="description-dropdown"
                >
                  <Dropdown.Toggle variant="dark" id="description-dropdown">
                    Description
                    <div id="arrow-icon" className="icon-overlay">
                      <MdArrowOutward size={20} />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      {selectedProduct.description.charAt(0).toUpperCase() + selectedProduct.description.slice(1)}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="social-icons">
                  <button className="icon-button">
                    <FaInstagram />
                  </button>
                  <button className="icon-button">
                    <FaYoutube />
                  </button>
                  <button className="icon-button">
                    <FaCube />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-9 xs-12">
          <div className="image-grid" style={{ maxHeight: "90vh" }}
           ref={imageGridRef}
          >
            {selectedProduct.images.map((image, index) => (
              <div
                key={index}
                className={`grid-item ${isLastRow(index) ? "last-row" : ""} ${clickedIndex === index ? "active" : ""}`}
                onClick={() => handleImageClick(index)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/${image}`}
                  
                  className="grid-image"
                  alt={`Product item ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>
        <div id="Description-section" className="col-md-3 col-sm-12 sidebar-section">
          <h3 style={{ fontWeight: "bold" }}>
            {selectedProduct.Productname.charAt(0).toUpperCase() + selectedProduct.Productname.slice(1)}
          </h3>
          <div id="single-text" className="sidebar p-4 bg-darkrounded tw-text-white">
            <ListGroup variant="flush">
              <ListGroup.Item action variant="dark" style={{ fontSize: "15px" }}>
                {selectedProduct.description.charAt(0).toUpperCase() + selectedProduct.description.slice(1)}
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="button-row mt-2" style={{ padding: "5px" }}>
            <div className="col-md-4">
              <button
                className={`transition-button ${activeButton === 0 ? "active" : ""}`}
                onClick={() => handleButtonClick(0)}
              >
                <span className="icon">
                  <FaYoutube />
                </span>
                <span className="text">
                  <span className="icon">
                    <FaYoutube />
                  </span>
                  See on YouTube
                </span>
              </button>
            </div>
            <div className="col-md-4">
              <button
                className={`transition-button ${activeButton === 1 ? "active" : ""}`}
                onClick={() => handleButtonClick(1)}
              >
                <span className="icon">
                  <FaInstagram />
                </span>
                <span className="text">
                  <span className="icon">
                    <FaInstagram />
                  </span>
                  See on Instagram
                </span>
              </button>
            </div>
            <div className="col-md-4">
              <button
                className={`transition-button ${activeButton === 2 ? "active" : ""}`}
                onClick={() => handleButtonClick(2)}
              >
                <span className="icon">
                  <FaCube />
                </span>
                <span className="text">
                  <span id="trans-btn" className="icon">
                    <FaCube />
                  </span>
                  Explore
                  <br />
                  AR
                </span>
              </button>
            </div>
          </div>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSf1nJBRFNLm2hYrS95oZvnK-FgSOeNEUIDcbLvAl7G_7p87Sg/viewform?fbclid=PAZXh0bgNhZW0CMTEAAaabIioPAYAnCxDaY2hFZjSf7qeU9qJGc_DuYZxtxI_G_nWUTiefpS62FNo_aem_KRmg-CrKFIxQiXf9Mtglow"
          >
            <button id="build-button" className="hover-button">
              <span>Build Your Dream</span>
            </button>
          </a>
        </div>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSf1nJBRFNLm2hYrS95oZvnK-FgSOeNEUIDcbLvAl7G_7p87Sg/viewform?fbclid=PAZXh0bgNhZW0CMTEAAaabIioPAYAnCxDaY2hFZjSf7qeU9qJGc_DuYZxtxI_G_nWUTiefpS62FNo_aem_KRmg-CrKFIxQiXf9Mtglow"
        >
          <button id="build-button" className="mobile-controls hover-button">
            <span>Build Your Dream</span>
          </button>
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default SingleProduct;