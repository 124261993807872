export const SingleprojectDetail = [
    {
      name: "ardete",
      youtubeLink: "https://www.youtube.com/shorts/Z_o_1Y3Cnpc?feature=share",
      instagramLink: "https://www.instagram.com/reel/C6x6Q5lSON8/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Studio Ardete HQ",
      metatittles:"Studio Ardete, Mohali | Parametric Facade with MetaFin & MetaSequin",
      metadescription:"Metaguise crafted a striking parametric facade for Ardete HQ, blending fluid MetaFin surfaces with dynamic MetaSequin elements for a bold, modern design",
      
      images: [
  
        "assets/Allprojects/commercial/Ardete/metafin/ardete3.jpg",
        "assets/Allprojects/commercial/Ardete/metafin/ardete4.jpg",
        "assets/Allprojects/commercial/Ardete/metafin/ardete8.jpg",
        "assets/Allprojects/commercial/Ardete/metafin/ardete14.jpg",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete1.jpg",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete2.jpg",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete6.jpg",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete9.jpg",
        "assets/Allprojects/commercial/Ardete/metasequin/ardete10.jpg",
        "assets/Allprojects/commercial/Ardete/night/ardete7.jpg",
        "assets/Allprojects/commercial/Ardete/night/ardete12.jpg",
        "assets/Allprojects/commercial/Ardete/night/ardete13.jpg",
      ],
    },
    {
      name: "tanishq",
      youtubeLink: "https://www.youtube.com/watch?v=nJSmApEceRE",
      instagramLink: "https://www.instagram.com/p/C7yTAU0y66T/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
      Projectname:"Tanishq Jewellery",
      metatittles:"Tanishq Jewellers, New Delhi | Iconic Parametric Facade with MetaCoin & MetaFlute",
      metadescription:"Metaguise designed a striking facade for Tanishq, featuring uniquely shaped parametric MetaCoin and wooden MetaFlute panels, creating a shimmering, jewel-like effect.",
      images: [
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq1.jpg",
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq5.jpg",
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq6.jpg",
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq7.jpg",
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq8.jpg",
        "assets/Allprojects/commercial/Tanishq/metacoin/tanishq9.jpg",
        "assets/Allprojects/commercial/Tanishq/metaflute/tanishq16.jpg",
        "assets/Allprojects/commercial/Tanishq/metaflute/tanishq3.jpg",
        "assets/Allprojects/commercial/Tanishq/metaflute/tanishq10.jpg",
        "assets/Allprojects/commercial/Tanishq/metaflute/tanishq17.jpg",
        "assets/Allprojects/commercial/Tanishq/night/tanishq2.jpg",
        "assets/Allprojects/commercial/Tanishq/night/tanishq4.jpg",
        "assets/Allprojects/commercial/Tanishq/night/tanishq12.jpg",
        "assets/Allprojects/commercial/Tanishq/night/tanishq13.jpg",
        "assets/Allprojects/commercial/Tanishq/night/tanishq14.jpg",
        "assets/Allprojects/commercial/Tanishq/night/tanishq15.jpg",
      ],
    },

    //Luxe
    {
      name: "Luxe",
      youtubeLink: "https://www.youtube.com/shorts/6Wk0hLXPGPo?feature=share",
      instagramLink: "https://www.instagram.com/reel/DDeZaTDyLPS/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Luxe Manor",
      metatittles:"Luxe Mansor, New Delhi | Iconic Parametric Facade with MetaCoin & MetaFlute",
      metadescription:"Metaguise designed a striking facade for Tanishq, featuring uniquely shaped parametric MetaCoin and wooden MetaFlute panels, creating a shimmering, jewel-like effect.",
      images: [
        "assets/Allprojects/residential/Luxe/night/luxe1.png",
        "assets/Allprojects/residential/Luxe/night/luxe2.png",
        "assets/Allprojects/residential/Luxe/night/luxe9.png",
        "assets/Allprojects/residential/Luxe/night/luxe11.png",
        "assets/Allprojects/residential/Luxe/night/luxe14.png",
        "assets/Allprojects/residential/Luxe/night/luxe15.png",
        "assets/Allprojects/residential/Luxe/metasequin/luxe3.png",
        "assets/Allprojects/residential/Luxe/metasequin/luxe5.png",
        "assets/Allprojects/residential/Luxe/metasequin/luxe7.png",
        "assets/Allprojects/residential/Luxe/metaflute/luxe6.png",
        "assets/Allprojects/residential/Luxe/metaflute/luxe13.png",
        "assets/Allprojects/residential/Luxe/metafin/luxe4.png",
        "assets/Allprojects/residential/Luxe/metafin/luxe8.png",
        "assets/Allprojects/residential/Luxe/metafin/luxe10.png",
        "assets/Allprojects/residential/Luxe/metafin/luxe12.png",
      ],
    },
    {
      name: "A13-43",
      youtubeLink: "https://www.youtube.com/watch?v=bsZgLDDyA3A",
      instagramLink: "https://www.instagram.com/p/CyTO-QXyZGj/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"A13-43",
      metatittles:"Modern Residential Facade with MetaPyramid, MetaLouver & MetaPlank",
      metadescription:"Metaguise crafted a bold residential facade for a modern home featuring MetaPyramid, MetaLover and MetaPlank",
      images: [
        "assets/Allprojects/residential/A13-43/metalouver/a13-433.jpg",
        "assets/Allprojects/residential/A13-43/metalouver/a13-437.jpg",
        "assets/Allprojects/residential/A13-43/metalouver/a13-4316.jpg",
        "assets/Allprojects/residential/A13-43/metaplank/a13-434.jpg",
        "assets/Allprojects/residential/A13-43/metaplank/a13-438.jpg",
        "assets/Allprojects/residential/A13-43/metaplank/a13-4310.jpg",
        "assets/Allprojects/residential/A13-43/metaplank/a13-4312.jpg",
        "assets/Allprojects/residential/A13-43/metapyramid/a13-432.jpg",
        "assets/Allprojects/residential/A13-43/metapyramid/a13-435.jpg",
        "assets/Allprojects/residential/A13-43/metapyramid/a13-436.jpg",
        "assets/Allprojects/residential/A13-43/metapyramid/a13-439.jpg",
        "assets/Allprojects/residential/A13-43/metapyramid/a13-4311.jpg",
        "assets/Allprojects/residential/A13-43/night/a13-4313.jpg",
        "assets/Allprojects/residential/A13-43/night/a13-4314.jpg",
        "assets/Allprojects/residential/A13-43/night/a13-4315.jpg",
      ],
    },
    {
      name: "D5-3",
      youtubeLink: "https://www.youtube.com/shorts/gE5wc_E-6Jg?feature=share",
      instagramLink: "https://www.instagram.com/p/DADNgGVyMxC/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"D5-3",
      metatittles:"Residential Facade with MetaCassette MetaFlute, MetaFold & MetaLouver",
      metadescription:"Metaguise designed a contemporary residential facade featuring MetaCassette grooved panels, MetaFlute, MetaFold, and MetaLouver for a refined, modern aesthetic",
      images: [
        "assets/Allprojects/residential/D5-3/metacassette-grooved/ankit10.jpg",
        "assets/Allprojects/residential/D5-3/metacassette-grooved/ankit11.jpg",
        "assets/Allprojects/residential/D5-3/metacassette-grooved/ankit12.jpg",
        "assets/Allprojects/residential/D5-3/metacassette-grooved/ankit13.jpg",
        "assets/Allprojects/residential/D5-3/metaflute/ankit6.jpg",
        "assets/Allprojects/residential/D5-3/metaflute/ankit9.jpg",
        "assets/Allprojects/residential/D5-3/metaflute/ankit14.jpg",
        "assets/Allprojects/residential/D5-3/metaflute/ankit15.jpg",
        "assets/Allprojects/residential/D5-3/metafold/ankit4.jpg",
        "assets/Allprojects/residential/D5-3/metafold/ankit5.jpg",
        "assets/Allprojects/residential/D5-3/metafold/ankit7.jpg",
        "assets/Allprojects/residential/D5-3/metafold/ankit8.jpg",
        "assets/Allprojects/residential/D5-3/metalouver/ankit16.jpg",
        "assets/Allprojects/residential/D5-3/metalouver/ankit2.jpg",
        "assets/Allprojects/residential/D5-3/metalouver/ankit3.jpg",
        "assets/Allprojects/residential/D5-3/metalouver/ankit17.jpg",
      ],
    },
    {
      name: "gowri",
      youtubeLink: "https://www.youtube.com/shorts/KzYLNPaMkB8?feature=share",
      instagramLink: "https://www.instagram.com/reel/C5VhWpTyXzx/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Gowri Jewelers",
      metatittles:"Gowri's Jewellery, Hyderabad | Radiant Facade with Parametric MetaSequin",
      metadescription:"Metaguise created a striking facade for Gowri's Jewellery, featuring MetaSequin’s dynamic parametric design that shimmers like a jewel in changing light",
      images: [
        "assets/Allprojects/commercial/Gowri/metasequin/gowri1.jpg",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri3.jpg",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri4.jpg",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri5.jpg",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri6.jpg",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri7.jpg",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri8.jpg",
        "assets/Allprojects/commercial/Gowri/metasequin/gowri9.jpg",
      ],
    },
    {
      name: "E10",
      youtubeLink: "https://www.youtube.com/shorts/gE5wc_E-6Jg?feature=share",
      instagramLink: "https://www.instagram.com/p/C0oeC5NSFp0/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"E10-HK",
      metatittles:"Commercial Facade | Fluid Parametric Design with MetaFin",
      metadescription:"This commercial facade features MetaFin’s fluid parametric design, creating seamless curves and a bold, modern architectural statement",
      images: [
        "assets/Allprojects/commercial/E10/metafin/e101.jpg",
        "assets/Allprojects/commercial/E10/metafin/e103.jpg",
        "assets/Allprojects/commercial/E10/metafin/e107.jpg",
        "assets/Allprojects/commercial/E10/metafin/e1010.jpg",
        "assets/Allprojects/commercial/E10/metafin/e1011.jpg",
        "assets/Allprojects/commercial/E10/night/e102.jpg",
        "assets/Allprojects/commercial/E10/night/e104.jpg",
        "assets/Allprojects/commercial/E10/night/e106.jpg",
        "assets/Allprojects/commercial/E10/night/e108.jpg",
        "assets/Allprojects/commercial/E10/night/e109.jpg",
        "assets/Allprojects/commercial/E10/night/e1012.jpg",
      ],
    },
    {
      name: "deepak",
      youtubeLink: "https://www.youtube.com/watch?v=dAarntEa15o",
      instagramLink: "https://www.instagram.com/p/C8bd64HyWOX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Deepak Sweets",
      metatittles:"Deepak Sweets, Bareilly | Striking Facade with MetaSequin, MetaPyramid & MetaCassette ",
      metadescription:"Metaguise designed a bold facade for Deepak Sweets, featuring MetaSequin, MetaPyramid, and perforated MetaCassette for depth, texture, and dynamic light play",
      images: [
        "assets/Allprojects/commercial/Deepak/metacassette-perforated/deepak6.jpg",
        "assets/Allprojects/commercial/Deepak/metacassette-perforated/deepak13.jpg",
        "assets/Allprojects/commercial/Deepak/metacassette-perforated/deepak16.jpg",
        "assets/Allprojects/commercial/Deepak/metacassette-perforated/deepak20.jpg",
        "assets/Allprojects/commercial/Deepak/metacassette-perforated/deepak21.jpg",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak3.jpg",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak5.jpg",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak8.jpg",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak15.jpg",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak18.jpg",
        "assets/Allprojects/commercial/Deepak/metapyramid/deepak19.jpg",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak17.jpg",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak2.jpg",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak11.jpg",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak22.jpg",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak14.jpg",
        "assets/Allprojects/commercial/Deepak/metasequin/deepak23.jpg",
        "assets/Allprojects/commercial/Deepak/night/deepak4.jpg",
        "assets/Allprojects/commercial/Deepak/night/deepak7.jpg",
        "assets/Allprojects/commercial/Deepak/night/deepak9.jpg",
        "assets/Allprojects/commercial/Deepak/night/deepak10.jpg",
        "assets/Allprojects/commercial/Deepak/night/deepak12.jpg",
      ],
    },
    {
      name: "A19",
      youtubeLink: "https://www.youtube.com/shorts/EerdVK4Ax9M?feature=share",
      instagramLink: "https://www.instagram.com/p/DBQQFHRyB7-/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"A19-DL1",
      metatittles:"Modern Residential Facade with MetaCassette, MetaFlute & MetaLouver",
      metadescription:"Metaguise crafted a refined residential facade featuring grooved and perforated MetaCassette, MetaFlute, and MetaLouver for a sleek, modern aesthetic",
      images: [
        "assets/Allprojects/residential/A19/metacassette-grooved/a1910.jpg",
        "assets/Allprojects/residential/A19/metacassette-grooved/a1911.jpg",
        "assets/Allprojects/residential/A19/metacassette-grooved/a1912.jpg",
        "assets/Allprojects/residential/A19/metacassette-grooved/a1913.jpg",
        "assets/Allprojects/residential/A19/metacassette-perforated/a199.jpg",
        "assets/Allprojects/residential/A19/metacassette-perforated/a1914.jpg",
        "assets/Allprojects/residential/A19/metacassette-perforated/a1915.jpg",
        "assets/Allprojects/residential/A19/metacassette-perforated/a1917.jpg",
        "assets/Allprojects/residential/A19/metaflute/a191.jpg",
        "assets/Allprojects/residential/A19/metaflute/a193.jpg",
        "assets/Allprojects/residential/A19/metaflute/a195.jpg",
        "assets/Allprojects/residential/A19/metaflute/a196.jpg",
        "assets/Allprojects/residential/A19/metalouver/a194.jpg",
        "assets/Allprojects/residential/A19/metalouver/a197.jpg",
        "assets/Allprojects/residential/A19/metalouver/a198.jpg",
        "assets/Allprojects/residential/A19/metalouver/a1916.jpg",
      ],
    },
    {
      name: "A18",
      youtubeLink: "https://www.youtube.com/watch?v=-K2JTghXJTw",
      instagramLink: "https://www.instagram.com/reel/Cy6EXmmSizr/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"A18-DL1",
      metatittles:"Elegant Residential Facade with MetaCassette, MetaFlute & MetaLouver",
      metadescription:"Metaguise designed a contemporary residential facade featuring perforated MetaCassette, MetaFlute, and MetaLouver for depth, ventilation, and modern aesthetics",
      images: [
        "assets/Allprojects/residential/A18/metacassette-perforated/a182.jpg",
        "assets/Allprojects/residential/A18/metacassette-perforated/a184.jpg",
        "assets/Allprojects/residential/A18/metacassette-perforated/a185.jpg",
        "assets/Allprojects/residential/A18/metacassette-perforated/a186.jpg",
        "assets/Allprojects/residential/A18/metacassette-perforated/a188.jpg",
        "assets/Allprojects/residential/A18/metacassette-perforated/a1812.jpg",
        "assets/Allprojects/residential/A18/metacassette-perforated/a1817.jpg",
        "assets/Allprojects/residential/A18/metacassette-perforated/a1819.jpg",
        "assets/Allprojects/residential/A18/metaflute/a189.jpg",
        "assets/Allprojects/residential/A18/metaflute/a1813.jpg",
        "assets/Allprojects/residential/A18/metaflute/a1814.jpg",
        "assets/Allprojects/residential/A18/metaflute/a1815.jpg",
        "assets/Allprojects/residential/A18/metaflute/a1816.jpg",
        "assets/Allprojects/residential/A18/metalouver/a183.jpg",
        "assets/Allprojects/residential/A18/metalouver/a1810.jpg",
        "assets/Allprojects/residential/A18/metalouver/a1811.jpg",
        "assets/Allprojects/residential/A18/metalouver/a1818.jpg",
      ],
    },
    {
      name: "MVA-DL1",
      Projectname:"MVA-DL1",
      metatittles:"Modern Residential Elevation with MetaCassette & MetaGate",
      metadescription:"Metaguise designed a sleek residential facade featuring a durable MetaGate and grooved MetaCassette panels, combining security with modern aesthetics",
      images: [
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun2.jpg",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun3.jpg",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun7.jpg",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun8.jpg",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun9.jpg",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun11.jpg",
        "assets/Allprojects/residential/MVA-DL1/metacassette-grooved/varun13.jpg",
        "assets/Allprojects/residential/MVA-DL1/metagate/varun10.jpg",
        "assets/Allprojects/residential/MVA-DL1/metagate/varun12.jpg",
        "assets/Allprojects/residential/MVA-DL1/metagate/varun14.jpg",
        "assets/Allprojects/residential/MVA-DL1/metagate/varun16.jpg",
        "assets/Allprojects/residential/MVA-DL1/night/varun1.jpg",
        "assets/Allprojects/residential/MVA-DL1/night/varun4.jpg",
        "assets/Allprojects/residential/MVA-DL1/night/varun6.jpg",
        "assets/Allprojects/residential/MVA-DL1/night/varun15.jpg",
      ],
    },
    {
      name: "metaland",
      youtubeLink: "https://www.youtube.com/watch?v=mwmFELxs14E",
      instagramLink: "https://www.instagram.com/reel/C6f5mGtSajX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Metaland",
      metatittles:" Metaguise's Flagship Experience Center with Parametric MetaSequin",
      metadescription:"Metaland is Metaguise’s flagship experience center, featuring MetaSequin’s parametric design and innovative metal craftsmanship",
      images: [
        "assets/Allprojects/commercial/Metaland/metasequin/metaland14.jpg",
        "assets/Allprojects/commercial/Metaland/metasequin/metaland8.jpg",
        "assets/Allprojects/commercial/Metaland/metasequin/metaland9.jpg",
        "assets/Allprojects/commercial/Metaland/metasequin/metaland10.jpg",
        "assets/Allprojects/commercial/Metaland/metasequin/metaland11.jpg",
        "assets/Allprojects/commercial/Metaland/night/metaland1.jpg",
        "assets/Allprojects/commercial/Metaland/night/metaland2.jpg",
        "assets/Allprojects/commercial/Metaland/night/metaland3.jpg",
        "assets/Allprojects/commercial/Metaland/night/metaland4.jpg",
        "assets/Allprojects/commercial/Metaland/night/metaland5.jpg",
        "assets/Allprojects/commercial/Metaland/night/metaland7.jpg",
        "assets/Allprojects/commercial/Metaland/night/metaland12.jpg",
        "assets/Allprojects/commercial/Metaland/night/metaland13.jpg",
      ],
    },
    {
      name: "micasa",
      youtubeLink: "https://www.youtube.com/watch?v=d_0Ie4xGsKQ&feature=youtu.be",
      instagramLink: "https://www.instagram.com/p/DAhz8F2S2Sf/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Micasa",
      metatittles:"Striking Commercial Facade with Parametric MetaCoin",
      metadescription:"Metaguise designed a bold commercial facade for Micasa, featuring MetaCoin’s parametric elements for a dynamic and eye-catching exterior",
      images: [
        "assets/Allprojects/commercial/Micasa/metacoin/micasa2.jpg",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa3.jpg",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa4.jpg",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa5.jpg",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa6.jpg",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa7.jpg",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa8.jpg",
        "assets/Allprojects/commercial/Micasa/metacoin/micasa9.jpg",
      ],
    },
    {
      name: "Yashika",
      youtubeLink: "https://www.youtube.com/shorts/PjSB1brakw0?feature=share",
      instagramLink: "https://www.instagram.com/p/C3Uxk3VSyQO/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Yashika",
      metatittles:"A Bold Commercial Facade with Parametric MetaCoin",
      metadescription:"Featuring parametric MetaCoin elements, this commercial facade creates a striking, dynamic visual identity that stands out in urban architecture",
      images: [
        "assets/Allprojects/commercial/yashika/metacoin/yashika2.jpg",
        "assets/Allprojects/commercial/yashika/metacoin/yashika3.jpg",
        "assets/Allprojects/commercial/yashika/metacoin/yashika4.jpg",
        "assets/Allprojects/commercial/yashika/metacoin/yashika5.jpg",
        "assets/Allprojects/commercial/yashika/metacoin/yashika6.jpg",
        "assets/Allprojects/commercial/yashika/metacoin/yashika7.jpg",
        "assets/Allprojects/commercial/yashika/metacoin/yashika8.jpg",
        "assets/Allprojects/commercial/yashika/metacoin/yashika9.jpg",
        "assets/Allprojects/commercial/yashika/metacoin/yashika10.jpg",
      ],
    },
    {
      name: "C76",
      youtubeLink: "https://www.youtube.com/watch?v=r6kNjqo6PgQ",
      instagramLink: "https://www.instagram.com/reel/C5lPK9QSXHU/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"C76",
      metatittles:"Modern Residential Facade | Grooved MetaCassette & MetaFlute",
      metadescription:"Enhance your residential facade with grooved MetaCassette panels and MetaFlute accents, designed for durability, depth, and a sleek modern look",
      images: [
        "assets/Allprojects/residential/C76/metacassette-grooved/c769.jpg",
        "assets/Allprojects/residential/C76/metacassette-grooved/c7612.jpg",
        "assets/Allprojects/residential/C76/metacassette-grooved/c7614.jpg",
        "assets/Allprojects/residential/C76/metacassette-grooved/c7619.jpg",
        "assets/Allprojects/residential/C76/metaflute/c762.jpg",
        "assets/Allprojects/residential/C76/metaflute/c763.jpg",
        "assets/Allprojects/residential/C76/metaflute/c768.jpg",
        "assets/Allprojects/residential/C76/metaflute/c7611.jpg",
        "assets/Allprojects/residential/C76/metaflute/c7613.jpg",
        "assets/Allprojects/residential/C76/metaflute/c7617.jpg",
        "assets/Allprojects/residential/C76/metaflute/c7618.jpg",
        "assets/Allprojects/residential/C76/night/c761.jpg",
        "assets/Allprojects/residential/C76/night/c764.jpg",
        "assets/Allprojects/residential/C76/night/c765.jpg",
        "assets/Allprojects/residential/C76/night/c767.jpg",
      ],
    },
    {
      name: "5107",
      youtubeLink: "https://www.youtube.com/shorts/oOiyN07PwQE?feature=share",
      instagramLink: "https://www.instagram.com/p/C0d5yYYS90q/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"5107",
      metatittles:"Modern Residential Facade | Perforated MetaCassette Panels",
      metadescription:"Upgrade your residential facade with perforated MetaCassette panels, offering a sleek design, ventilation, and modern architectural appeal",
      images: [
        "assets/Allprojects/residential/5107/metacassette-perforated/51072.jpg",
        "assets/Allprojects/residential/5107/metacassette-perforated/51073.jpg",
        "assets/Allprojects/residential/5107/metacassette-perforated/51074.jpg",
        "assets/Allprojects/residential/5107/metacassette-perforated/51078.jpg",
        "assets/Allprojects/residential/5107/metacassette-perforated/510710.jpg",
        "assets/Allprojects/residential/5107/metacassette-perforated/510711.jpg",
        "assets/Allprojects/residential/5107/night/51074.jpg",
        "assets/Allprojects/residential/5107/night/51075.jpg",
        "assets/Allprojects/residential/5107/night/51077.jpg",
        "assets/Allprojects/residential/5107/night/51079.jpg",
        "assets/Allprojects/residential/5107/night/510712.jpg",
        "assets/Allprojects/residential/5107/night/510713.jpg",
        "assets/Allprojects/residential/5107/night/510714.jpg",
      ],
    },
    {
      name: "A297",
      youtubeLink: "https://www.youtube.com/watch?v=_ei1jkRmp_Q",
      instagramLink: "https://www.instagram.com/p/C3KeYlvyFdK/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"A297",
      metatittles:"MetaShingle, MetaFlute & MetaLouver",
      metadescription:"Enhance your home's facade with MetaFlute, MetaLouver, and MetaShingle—combining durability, ventilation, and modern aesthetics",
      images: [
        "assets/Allprojects/residential/A297/metaflute/a2973.jpg",
        "assets/Allprojects/residential/A297/metaflute/a2975.jpg",
        "assets/Allprojects/residential/A297/metaflute/a2976.jpg",
        "assets/Allprojects/residential/A297/metaflute/a2979.jpg",
        "assets/Allprojects/residential/A297/metaflute/a29713.jpg",
        "assets/Allprojects/residential/A297/metagate/a2974.jpg",
        "assets/Allprojects/residential/A297/metagate/a29715.jpg",
        "assets/Allprojects/residential/A297/metagate/a29716.jpg",
        "assets/Allprojects/residential/A297/metalouver/a2977.jpg",
        "assets/Allprojects/residential/A297/metalouver/a29712.jpg",
        "assets/Allprojects/residential/A297/metalouver/a29714.jpg",
        "assets/Allprojects/residential/A297/metalouver/a29717.jpg",
        "assets/Allprojects/residential/A297/metashingle/a2972.jpg",
        "assets/Allprojects/residential/A297/metashingle/a2978.jpg",
        "assets/Allprojects/residential/A297/metashingle/a29710.jpg",
        "assets/Allprojects/residential/A297/metashingle/a29711.jpg",
      ],
    },
    // {
    //   name: "Chandna",
    //   images: [
    //     "assets/Allprojects/residential/Chandna/metaflute/chandna2.jpg",
    //     "assets/Allprojects/residential/Chandna/metaflute/chandna3.jpg",
    //     "assets/Allprojects/residential/Chandna/metaflute/chandna4.jpg",
    //     "assets/Allprojects/residential/Chandna/metaflute/chandna5.jpg",
    //     "assets/Allprojects/residential/Chandna/metaflute/chandna6.jpg",
    //     "assets/Allprojects/residential/Chandna/metaflute/chandna7.jpg",
    //     "assets/Allprojects/residential/Chandna/metaflute/chandna8.jpg",
    //     "assets/Allprojects/residential/Chandna/metaflute/chandna9.jpg",
    //   ],
    // },
    {
      name: "C34-573",
      youtubeLink: "https://www.youtube.com/watch?v=O99SARUeRa0",
      instagramLink: "https://www.instagram.com/p/C8wEVfgSrCF/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"C3/4-S73",
      metatittles:"Parametric MetaCoin & Overlapping MetaShingle",
      metadescription:"Upgrade your home's facade with MetaCoin and MetaShingle—combining parametric elegance and layered textures for a modern architectural look",
      images: [
        "assets/Allprojects/residential/C34-573/metacoin/kapil2.jpg",
        "assets/Allprojects/residential/C34-573/metacoin/kapil3.jpg",
        "assets/Allprojects/residential/C34-573/metacoin/kapil4.jpg",
        "assets/Allprojects/residential/C34-573/metacoin/kapil6.jpg",
        "assets/Allprojects/residential/C34-573/metacoin/kapil8.jpg",
        "assets/Allprojects/residential/C34-573/metacoin/kapil9.jpg",
        "assets/Allprojects/residential/C34-573/metacoin/kapil10.jpg",
        "assets/Allprojects/residential/C34-573/metashingle/kapil11.jpg",
        "assets/Allprojects/residential/C34-573/metashingle/kapil5.jpg",
        "assets/Allprojects/residential/C34-573/metashingle/kapil7.jpg",
        "assets/Allprojects/residential/C34-573/metashingle/kapil12.jpg",
      ],
    },
    {
      name: "DLF4",
      youtubeLink: "https://www.youtube.com/watch?v=gi0qDnaOmfI",
      instagramLink: "https://www.instagram.com/p/C2uQpm1y7JB/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"DLF4",
      metatittles:"Residential Facade | Sleek MetaFlute & Solid Panel Design",
      metadescription:"Enhance your home's facade with MetaFlute and solid panels—offering durability, sleek lines, and a modern aesthetic with minimal maintenance",
      images: [
        "assets/Allprojects/residential/DLF4/metaflute/dlf15.jpg",
        "assets/Allprojects/residential/DLF4/metaflute/dlf16.jpg",
        "assets/Allprojects/residential/DLF4/metaflute/dlf17.jpg",
        "assets/Allprojects/residential/DLF4/metaflute/dlf48.jpg",
        "assets/Allprojects/residential/DLF4/night/dlf42.jpg",
        "assets/Allprojects/residential/DLF4/night/dlf43.jpg",
        "assets/Allprojects/residential/DLF4/night/dlf45.jpg",
        "assets/Allprojects/residential/DLF4/night/dlf46.jpg",
        "assets/Allprojects/residential/DLF4/night/dlf47.jpg",
        "assets/Allprojects/residential/DLF4/night/dlf49.jpg",
        "assets/Allprojects/residential/DLF4/night/dlf412.jpg",
        "assets/Allprojects/residential/DLF4/solid-panel/dlf14.jpg",
        "assets/Allprojects/residential/DLF4/solid-panel/dlf410.jpg",
        "assets/Allprojects/residential/DLF4/solid-panel/dlf411.jpg",
        "assets/Allprojects/residential/DLF4/solid-panel/dlf413.jpg",
      ],
    },
    {
      name: "CS5-MA",
      youtubeLink: "https://www.youtube.com/watch?v=6RoELQ5I-1g",
      instagramLink: "https://www.instagram.com/p/CyDrwYkSG7e/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"CS5-MA",
      metatittles:"Residential Facade | Perforated MetaCassette, MetaFlute & MetaLouve",
      metadescription:"Upgrade your home's facade with perforated MetaCassette, MetaFlute, and MetaLouver—offering ventilation, depth, and a sleek modern design",
      images: [
        "assets/Allprojects/residential/CS5-MA/metacassette-perforated/vishal2.jpg",
        "assets/Allprojects/residential/CS5-MA/metacassette-perforated/vishal9.jpg",
        "assets/Allprojects/residential/CS5-MA/metacassette-perforated/vishal13.jpg",
        "assets/Allprojects/residential/CS5-MA/metacassette-perforated/vishal14.jpg",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal3.jpg",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal4.jpg",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal5.jpg",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal6.jpg",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal8.jpg",
        "assets/Allprojects/residential/CS5-MA/metaflute/vishal11.jpg",
        "assets/Allprojects/residential/CS5-MA/metalouver/vishal7.jpg",
        "assets/Allprojects/residential/CS5-MA/metalouver/vishal21.jpg",
        "assets/Allprojects/residential/CS5-MA/metalouver/vishal22.jpg",
        "assets/Allprojects/residential/CS5-MA/metalouver/vishal23.jpg",
        "assets/Allprojects/residential/CS5-MA/night/vishal10.jpg",
        "assets/Allprojects/residential/CS5-MA/night/vishal12.jpg",
        "assets/Allprojects/residential/CS5-MA/night/vishal20.jpg",
        "assets/Allprojects/residential/CS5-MA/night/vishal24.jpg",
      ],
    },
    {
      name: "espace",
      youtubeLink: "https://www.youtube.com/watch?v=0Or6FQP59x4",
      instagramLink: "https://www.instagram.com/p/C4NN0B3yv82/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Espace Nirvana",
      metatittles:"Residential Facade | Perforated MetaCassette, MetaLouver & MetaPlank",
      metadescription:"Transform your home's facade with perforated MetaCassette, grey and wood-tone MetaLouver, and wood-finish MetaPlank for a modern, textured look",
      images: [
        "assets/Allprojects/residential/Espace/metacassette-perforated/espace2.jpg",
        "assets/Allprojects/residential/Espace/metacassette-perforated/espace5.jpg",
        "assets/Allprojects/residential/Espace/metacassette-perforated/espace8.jpg",
        "assets/Allprojects/residential/Espace/metacassette-perforated/espace12.jpg",
        "assets/Allprojects/residential/Espace/metalouver-grey/espace3.jpg",
        "assets/Allprojects/residential/Espace/metalouver-grey/espace6.jpg",
        "assets/Allprojects/residential/Espace/metalouver-grey/espace9.jpg",
        "assets/Allprojects/residential/Espace/metalouver-grey/espace15.jpg",
        "assets/Allprojects/residential/Espace/metalouver-wood/espace17.jpg",
        "assets/Allprojects/residential/Espace/metalouver-wood/espace4.jpg",
        "assets/Allprojects/residential/Espace/metalouver-wood/espace14.jpg",
        "assets/Allprojects/residential/Espace/metalouver-wood/espace16.jpg",
        "assets/Allprojects/residential/Espace/metaplank-wood/espace7.jpg",
        "assets/Allprojects/residential/Espace/metaplank-wood/espace18.jpg",
        "assets/Allprojects/residential/Espace/metaplank-wood/espace19.jpg",
        "assets/Allprojects/residential/Espace/metaplank-wood/espace20.jpg",
      ],
    },
    {
      name: "MA-543",
      youtubeLink: "https://www.youtube.com/watch?v=Urze-UjHcLU",
      instagramLink: "https://www.instagram.com/p/C_CsfuZy_UM/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"MA-543",
      metatittles:"Residential Facade | Parametric MetaCoin, MetaLouver & MetaPlank",
      metadescription:"Enhance your home's facade with parametric MetaCoin, MetaLouver for ventilation, and wood-finish MetaPlank for a dynamic and modern look",
      images: [
        "assets/Allprojects/residential/MA-543/metacoin/manoj8.jpg",
        "assets/Allprojects/residential/MA-543/metacoin/manoj10.jpg",
        "assets/Allprojects/residential/MA-543/metacoin/manoj11.jpg",
        "assets/Allprojects/residential/MA-543/metacoin/manoj4.jpg",
        "assets/Allprojects/residential/MA-543/metalouver/manoj2.jpg",
        "assets/Allprojects/residential/MA-543/metalouver/manoj5.jpg",
        "assets/Allprojects/residential/MA-543/metalouver/manoj7.jpg",
        "assets/Allprojects/residential/MA-543/metalouver/manoj9.jpg",
        "assets/Allprojects/residential/MA-543/metaplank/manoj3.jpg",
        "assets/Allprojects/residential/MA-543/metaplank/manoj6.jpg",
        "assets/Allprojects/residential/MA-543/metaplank/manoj12.jpg",
        "assets/Allprojects/residential/MA-543/metaplank/manoj13.jpg",
      ],
    },
    {
      name: "Q62-RA",
      
      youtubeLink:"https://www.youtube.com/watch?v=oEKOjT0tAWk",
      instagramLink:"https://www.instagram.com/p/C_m5fBNhNuX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
      Projectname:"Q62-RA",
      metatittles:"Residential Facade | MetaFlute, MetaLouver & Solid Panels",
      metadescription:"Upgrade your home's facade with MetaFlute for texture, MetaLouver for ventilation, and solid panels for a sleek, durable, and modern finish",
      images: [
        "assets/Allprojects/residential/Q62-RA/metaflute/tejbir4.jpg",
        "assets/Allprojects/residential/Q62-RA/metaflute/tejbir6.jpg",
        "assets/Allprojects/residential/Q62-RA/metaflute/tejbir7.jpg",
        "assets/Allprojects/residential/Q62-RA/metaflute/tejbir14.jpg",
        "assets/Allprojects/residential/Q62-RA/metalouver/tejbir2.jpg",
        "assets/Allprojects/residential/Q62-RA/metalouver/tejbir3.jpg",
        "assets/Allprojects/residential/Q62-RA/metalouver/tejbir8.jpg",
        "assets/Allprojects/residential/Q62-RA/metalouver/tejbir11.jpg",
        "assets/Allprojects/residential/Q62-RA/metalouver/tejbir13.jpg",
        "assets/Allprojects/residential/Q62-RA/solid-panel/tejbir5.jpg",
        "assets/Allprojects/residential/Q62-RA/solid-panel/tejbir9.jpg",
        "assets/Allprojects/residential/Q62-RA/solid-panel/tejbir10.jpg",
        "assets/Allprojects/residential/Q62-RA/solid-panel/tejbir12.jpg",
      ],
    },
    {
      name: "z1012",
      youtubeLink: "",
      instagramLink: "https://www.instagram.com/p/DA3Pqdey03I/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      Projectname:"Z1012",
      metatittles:"Residential Facade | MetaFlute & MetaShingle for a Modern Look",
      metadescription:"Elevate your home's facade with MetaFlute for sleek linear detail and MetaShingle for layered depth, creating a refined and contemporary aesthetic",
      images: [
        "assets/Allprojects/residential/z1012/metaflute/z10122.jpg",
        "assets/Allprojects/residential/z1012/metaflute/z10123.jpg",
        "assets/Allprojects/residential/z1012/metaflute/z10128.jpg",
        "assets/Allprojects/residential/z1012/metaflute/z101210.jpg",
        "assets/Allprojects/residential/z1012/metaflute/z101211.jpg",
        "assets/Allprojects/residential/z1012/metashingle/z10124.jpg",
        "assets/Allprojects/residential/z1012/metashingle/z10125.jpg",
        "assets/Allprojects/residential/z1012/metashingle/z10126.jpg",
        "assets/Allprojects/residential/z1012/metashingle/z10127.jpg",
        "assets/Allprojects/residential/z1012/metashingle/z10129.jpg",
      ],
    },
  ];

export const SingleProductDetail = [
    {
      name: "MetaCoin",
      Productname:"MetaCoin",
      metatittles:"Customizable Parametric Facade System",
      metadescription:"Customize every detail of your facade with MetaCoin—control shape, size, color, and pattern for a unique architectural design.",
      description:
        "A revolutionary parametric facade system designed to elevate architectural aesthetics. Crafted with premium metals and enhanced with MetaSurface finishes, MetaCoin offers unparalleled customizability, from the shape and size of each element to its color and arrangement. Its dynamic design reacts to light and perspective, creating ever-changing visual effects. Ideal for contemporary and iconic structures, MetaCoin transforms facades into captivating works of art that are sustainable, durable, and visually stunning​​.",
      images: [
        "assets/Allproducts/MetaParametric/metacoin/metacoin1.jpg",
        "assets/Allproducts/MetaParametric/metacoin/metacoin2.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin3.jpg",
        "assets/Allproducts/MetaParametric/metacoin/metacoin4.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin5.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin6.jpg",
        "assets/Allproducts/MetaParametric/metacoin/metacoin7.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin8.jpg",
        "assets/Allproducts/MetaParametric/metacoin/metacoin9.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin10.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin11.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin12.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin13.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin14.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin15.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin16.png",
        "assets/Allproducts/MetaParametric/metacoin/metacoin17.png",
      ],
    },
    {
      name: "MetaSequin",
      Productname:"MetaSequin",
      metatittles:"Dynamic Parametric Facade with Custom Design",
      metadescription:"MetaSequin is a customizable parametric facade system, creating dynamic surfaces that shift with light, angle, and perspective.",
      description:
        "Elevate your structure with MetaShingles, a system of overlapping metal panels designed to create dynamic textures and visual depth. Fully customizable in shape, size, color and finish, MetaShingles offer endless design possibilities to suit any architectural vision. These shingles bring a contemporary flair to facades while ensuring durability and weather resistance. Perfect for residential and commercial projects, MetaShingles strike a balance between aesthetic appeal and robust functionality, allowing you to tailor your facade to your unique style​.",
      images: [
        "assets/Allproducts/MetaParametric/metasequin/metasequin2.png",
        "assets/Allproducts/MetaParametric/metasequin/metasequin3.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin4.png",
        "assets/Allproducts/MetaParametric/metasequin/metasequin5.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin6.png",
        "assets/Allproducts/MetaParametric/metasequin/metasequin7.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin8.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin9.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin10.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin11.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin12.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin13.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin14.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin15.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin16.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin17.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin18.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin19.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin20.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin22.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin23.jpg",
        "assets/Allproducts/MetaParametric/metasequin/metasequin24.jpg",
      ],
    },
    {
      name: "MetaPyramid",
      Productname:"MetaPyramids",
      metatittles:"3D Metal Facade Inspired by Egypt’s Pyramids",
      metadescription:"MetaPyramids is a 3D metal facade system inspired by Egyptian pyramids, featuring fixed pyramid-shaped panels that add depth and shadows",
      description:
        "A tribute to ancient architectural wonders, MetaPyramid captures the essence of the pyramids with its geometric precision. Featuring 3D perforations that play with light and shadow, these facades create dynamic visual patterns that change throughout the day. Ideal for bold and iconic designs, MetaPyramid adds a layer of intrigue and timeless sophistication to modern architecture​.",
      images: [
        "assets/Allproducts/MetaForm/metapyramid/metapyramid1.jpg",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid2.png",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid3.jpg",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid4.png",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid5.jpg",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid6.png",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid7.jpg",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid8.png",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid9.jpg",
        "assets/Allproducts/MetaForm/metapyramid/metapyramid10.jpg",
      ],
    },
    {
      name: "MetaShingle",
      Productname:"MetaShingles",
      metatittles:"Overlapping Metal Facade System",
      metadescription:"MetaShingles is a metal facade system with interlocking, overlapping panels that enhance texture, create depth, and play with light and shadow.",
      description:
        "Elevate your structure with MetaShingles, a system of overlapping metal panels designed to create dynamic textures and visual depth. These shingles bring a contemporary flair to facades while ensuring durability and weather resistance. Perfect for residential and commercial projects, MetaShingles strike a balance between aesthetic appeal and robust functionality​.",
      images: [
        "assets/Allproducts/MetaForm/metashingle/metashingle1.png",
        "assets/Allproducts/MetaForm/metashingle/metashingle2.png",
        "assets/Allproducts/MetaForm/metashingle/metashingle3.png",
        "assets/Allproducts/MetaForm/metashingle/metashingle4.png",
        "assets/Allproducts/MetaForm/metashingle/metashingle5.png",
        "assets/Allproducts/MetaForm/metashingle/metashingle6.png",
        "assets/Allproducts/MetaForm/metashingle/metashingle7.png",
        "assets/Allproducts/MetaForm/metashingle/metashingle8.png",
        "assets/Allproducts/MetaForm/metashingle/metashingle9.png",
        "assets/Allproducts/MetaForm/metashingle/metashingle10.png",
        "assets/Allproducts/MetaForm/metashingle/metashingle11.jpg",
        "assets/Allproducts/MetaForm/metashingle/metashingle12.jpg",
      ],
    },
    {
      name: "CascadingKeys",
      Productname:"Cascading Keys",
      metatittles:"Parametric Metal Facade with Fluid Design",
      metadescription:"Cascading Keys is a parametric metal facade with layered, flowing panels that create movement, dynamic depth, and interactive light effects.",
      description:
        "An architectural ode to natural harmony, Cascading Keys incorporates aluminum and stainless steel in flowing forms that mimic the rhythm of nature. These facades resemble the gentle hum of wind chimes or the flutter of butterfly wings, captivating viewers with their elegance. Ideal for tranquil, design-forward spaces, Cascading Keys creates a seamless blend of beauty and function​.",
      images: [
        "assets/Allproducts/MetaForm/CascadingKeys/caskey2.png",
        "assets/Allproducts/MetaForm/CascadingKeys/caskey3.png",
        "assets/Allproducts/MetaForm/CascadingKeys/caskey4.png",
        "assets/Allproducts/MetaForm/CascadingKeys/caskey5.png",
        "assets/Allproducts/MetaForm/CascadingKeys/caskey6.png",
      ],
    },
    {
      name: "MetaCassette",
      Productname:"MetaCassette",
      metatittles:"Versatile Metal Cladding for Facades",
      metadescription:"MetaCassette is a metal cladding system with solid, perforated, and grooved panels, offering durability, ventilation, and modern aesthetics.",
      description:
        "A versatile facade solution, MetaCassette offers three distinct panel types—grooved, perforated, and solid—each designed to bring unique aesthetic and functional qualities to architectural projects. Together, these options empower architects and designers to craft facades that are not only visually captivating but also tailored to specific functional requirements​​.",
      images: [
        "assets/Allproducts/MetaForm/metacassette/metacassette1.png",
        "assets/Allproducts/MetaForm/metacassette/metacassette2.jpg",
        "assets/Allproducts/MetaForm/metacassette/metacassette3.png",
        "assets/Allproducts/MetaForm/metacassette/metacassette4.jpg",
        "assets/Allproducts/MetaForm/metacassette/metacassette5.jpg",
        "assets/Allproducts/MetaForm/metacassette/metacassette6.png",
        "assets/Allproducts/MetaForm/metacassette/metacassette7.jpg",
        "assets/Allproducts/MetaForm/metacassette/metacassette8.png",
        "assets/Allproducts/MetaForm/metacassette/metacassette9.jpg",
        "assets/Allproducts/MetaForm/metacassette/metacassette10.png",
        "assets/Allproducts/MetaForm/metacassette/metacassette11.png",
        "assets/Allproducts/MetaForm/metacassette/metacassette12.png",
        "assets/Allproducts/MetaForm/metacassette/metacassette13.png",
        "assets/Allproducts/MetaForm/metacassette/metacassette14.png",
        "assets/Allproducts/MetaForm/metacassette/metacassette15.jpg",
        "assets/Allproducts/MetaForm/metacassette/metacassette16.jpg",
        "assets/Allproducts/MetaForm/metacassette/metacassette17.jpg",
        "assets/Allproducts/MetaForm/metacassette/metacassette19.jpg",
        "assets/Allproducts/MetaForm/metacassette/metacassette20.jpg",
        "assets/Allproducts/MetaForm/metacassette/metacassette21.jpg",
      ],
    },
    {
      name: "MetaFlute",
      Productname:"MetaFlute",
      metatittles:"Durable Metal Cladding with Linear Profiles",
      metadescription:"MetaFlute is a metal cladding system with strong, linear profiles that add depth, texture, and a sleek, modern touch to facades.",
      description:
        "A sleek solution for architectural facades, MetaFlute combines lightweight yet durable metals to create rhythmic, vertical lines that enhance any structure. These extrusions shield buildings from harsh weather while adding a touch of modern sophistication. MetaFlute is ideal for projects requiring elegance, resilience, and minimal maintenance​​.",
      images: [
        "assets/Allproducts/MetaForm/metaflute/metaflute1.jpg",
        "assets/Allproducts/MetaForm/metaflute/metaflute2.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute3.jpg",
        "assets/Allproducts/MetaForm/metaflute/metaflute4.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute5.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute6.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute7.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute8.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute9.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute10.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute11.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute13.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute14.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute15.png",
        "assets/Allproducts/MetaForm/metaflute/metaflute16.png",
      ],
    },
    {
      name: "MetaFold",
      Productname:"MetaFold",
      metatittles:"Folding Metal Facade System for Flexible Design",
      metadescription:"MetaFold is a folding metal facade system with multi-faceted panels that create dynamic openings, seamless transitions, and modern aesthetics.",
      description:
        "MetaFold transforms spaces with its innovative folding panels, seamlessly connecting indoor and outdoor environments. Featuring multi-faceted panes crafted from premium metals, it creates dynamic openings that enhance airflow and natural light. Perfect for modern homes and commercial spaces, MetaFold embodies functional luxury and design flexibility​.",
      images: [
        "assets/Allproducts/MetaFunction/metafold/metafold2.png",
        "assets/Allproducts/MetaFunction/metafold/metafold3.png",
        "assets/Allproducts/MetaFunction/metafold/metafold4.jpg",
        "assets/Allproducts/MetaFunction/metafold/metafold5.jpg",
        "assets/Allproducts/MetaFunction/metafold/metafold6.jpg",
      ],
    },
    {
      name: "MetaFin",
      Productname:"MetaFin",
      metatittles:"Parametric Metal Facade with Fluid Curves",
      metadescription:"MetaFin is a parametric metal facade system with seamless, curvilinear surfaces that create flowing, organic forms for modern architecture.",
      description:
        "Showcasing the perfect blend of fluidity and structure, MetaFin features parametric designs crafted from lightweight metals. With its smooth, organic curves and ability to reflect and play with light, MetaFin creates a stunning visual narrative. This facade system is ideal for projects that demand sustainable, artistic, and high-performance solutions​​.",
      images: [
        "assets/Allproducts/MetaParametric/metafin/metafin2.png",
        "assets/Allproducts/MetaParametric/metafin/metafin1.png",
        "assets/Allproducts/MetaParametric/metafin/metafin3.png",
        "assets/Allproducts/MetaParametric/metafin/metafin4.png",
        "assets/Allproducts/MetaParametric/metafin/metafin5.png",
        "assets/Allproducts/MetaParametric/metafin/metafin6.png",
      ],
    },
    {
      name: "MetaHydra",
      Productname:"MetaHydra",
      metatittles:"Sculpted 3D Copper Facade with Living Patina",
      metadescription:"MetaHydra is a 3D metal facade crafted from pure copper, evolving with a living patina to create dynamic colors and organic movement",
      description:
        "MetaHydra brings together the beauty of copper and the resilience of parametric design. Its unique panels mimic the fluidity of water, transforming facades into living works of art. The pure copper material ages gracefully, developing a rich patina that adds character over time, making it a luxurious choice for iconic architectural projects​.",
      images: [
        "assets/Allproducts/MetaParametric/metahydra/metahydra1.png",
        "assets/Allproducts/MetaParametric/metahydra/metahydra2.png",
        "assets/Allproducts/MetaParametric/metahydra/metahydra3.png",
        "assets/Allproducts/MetaParametric/metahydra/cover/metahydra.png",
      ],
    },
    {
      name: "MetaLouver",
      Productname:"MetaLouver",
      metatittles:"Angled Metal Louvers for Light & Ventilation",
      metadescription:"MetaLouver is a metal louver system with precisely angled panels that balance natural light, airflow, and sun protection for facades",
      description:
        "MetaLouver offers a harmonious balance of form and functionality. Its adjustable, angular metal panels allow for optimal control of light and ventilation while shielding interiors from rain and harsh sunlight. Whether used for facades or shading systems, MetaLouver adds a distinctive architectural rhythm to buildings​​.",
      images: [
        "assets/Allproducts/MetaForm/metalouver/metalouver1.jpg",
        "assets/Allproducts/MetaForm/metalouver/metalouver2.png",
        "assets/Allproducts/MetaForm/metalouver/metalouver3.jpg",
        "assets/Allproducts/MetaForm/metalouver/metalouver4.png",
        "assets/Allproducts/MetaForm/metalouver/metalouver5.jpg",
        "assets/Allproducts/MetaForm/metalouver/metalouver6.jpg",
        "assets/Allproducts/MetaForm/metalouver/metalouver7.jpg",
        "assets/Allproducts/MetaForm/metalouver/metalouver8.jpg",
        "assets/Allproducts/MetaForm/metalouver/metalouver9.jpg",
      ],
    },
    {
      name: "MetaPlank",
      Productname:"MetaPlank",
      metatittles:"Sleek Metal Cladding with Linear Extrusions",
      metadescription:"MetaPlank is a metal cladding system featuring linear extrusions that provide a clean, modern facade with durability and low maintenance",
      description:
        "MetaPlank combines the natural appeal of wood with the resilience of metal, offering a durable, weather-resistant cladding solution. Its earthy tones and realistic textures bring warmth to any structure, while its maintenance-free properties make it a sustainable and cost-effective alternative to traditional wood​​.",
      images: [
        "assets/Allproducts/MetaForm/metaplank/metaplank1.png",
        "assets/Allproducts/MetaForm/metaplank/metaplank3.jpg",
        "assets/Allproducts/MetaForm/metaplank/metaplank4.jpg",
        "assets/Allproducts/MetaForm/metaplank/metaplank5.jpg",
        "assets/Allproducts/MetaForm/metaplank/metaplank6.jpg",
      ],
    },
    {
      name: "MetaGate",
      Productname:"MetaGate",
      metatittles:"Premium Metal Gates with Manual & Auto Options",
      metadescription:"MetaGate is a high-end metal gate system offering swing, sliding, and bi-fold functions with optional automation for seamless operation",
      description:
        "A stylish and functional entry solution, MetaGate is crafted from lightweight aluminum and can be customized with manual or automated sliding, swinging, or folding mechanisms. Offering sleek aesthetics and robust performance, it’s the perfect addition to both contemporary and classic architectural designs​.",
      images: [
        "assets/Allproducts/MetaFunction/metagate/metagate1.png",
        "assets/Allproducts/MetaFunction/metagate/metagate2.png",
        "assets/Allproducts/MetaFunction/metagate/metagate3.jpg",
        "assets/Allproducts/MetaFunction/metagate/metagate5.jpg",
        "assets/Allproducts/MetaFunction/metagate/metagate6.jpg",
        "assets/Allproducts/MetaFunction/metagate/metagate7.jpg",
      ],
    },
    {
      name: "MetaSlider",
      Productname:"MetaSlider",
      metatittles:"Sliding Metal Facade for Adaptive Architecture",
      metadescription:"MetaSlider is a metal facade system with sliding panels that adjust for light control, ventilation, and aesthetic flexibility in design",
      description:
        " MetaSlider integrates sliding facade technology to create dynamic architectural expressions. Whether open to embrace natural light or closed for privacy, it adapts seamlessly to climatic and aesthetic needs. MetaSlider’s versatility makes it a go-to solution for modern facades that balance functionality and design​.",
      images: [
        "assets/Allproducts/MetaFunction/metaslider/metaslider1.png",
        "assets/Allproducts/MetaFunction/metaslider/metaslider3.jpg",
        "assets/Allproducts/MetaFunction/metaslider/cover/metaslider.png",
      ],
    },
    {
      name: "MetaBlox",
      Productname:"MetaBlox",
      metatittles:"Modular Shape-Changing Metal Cladding System",
      metadescription:"MetaBlox is a shape-changing metal cladding system made of modular aluminum blocks, creating dynamic, customizable facades",
      description:
        "The first-ever shape-changing facade system, MetaBlox combines cutting-edge parametric technology with visually mesmerizing designs. Made from pure aluminum, it offers a unique, customizable facade solution that adapts to light and perspective, making every project a masterpiece​.",
      images: [
        "assets/Allproducts/MetaParametric/metablox/metablox1.png",
        "assets/Allproducts/MetaParametric/metablox/metablox2.jpg",
        "assets/Allproducts/MetaParametric/metablox/cover/metablox.png",
      ],
    },
    {
      name: "MetaCopper",
      Productname:"MetaCopper",
      metatittles:" Pure Copper Finish for Timeless Elegance",
      metadescription:"MetaCopper is a premium pure copper surface with a natural warm hue, offering durability, antimicrobial properties, and a refined finish.",
      description:
        "A timeless material reimagined, MetaCopper integrates the warmth and character of copper into modern architectural designs. Its rustic ochre-red hues, combined with its durability and resistance to corrosion, make it a stunning choice for facades that exude both tradition and innovation​.",
      images: [
        "assets/Allproducts/MetaSurface/metacopper/metacopper2.png",
        "assets/Allproducts/MetaSurface/metacopper/metacopper3.png",
        "assets/Allproducts/MetaSurface/metacopper/metacopper4.png",
        "assets/Allproducts/MetaSurface/metacopper/metacopper5.png",
        "assets/Allproducts/MetaSurface/metacopper/metacopper6.png",
        "assets/Allproducts/MetaSurface/metacopper/metacopper7.png",
        "assets/Allproducts/MetaSurface/metacopper/metacopper8.png",
        "assets/Allproducts/MetaSurface/metacopper/metacopper9.png",
        "assets/Allproducts/MetaSurface/metacopper/metacopper10.png",
        "assets/Allproducts/MetaSurface/metacopper/metacopper11.png",
        "assets/Allproducts/MetaSurface/metacopper/metacopper12.png",
      ],
    },
    {
      name: "MetaCorten",
      Productname:"MetaCorten",
      metatittles:"Weathered Steel Surface with a Rustic Finish",
      metadescription:"MetaCorten is a corrosion-resistant weathered steel surface that develops a rich, rust-like patina while remaining maintenance-free.",
      description:
        "Embracing the raw beauty of weathered steel, MetaCorten offers a rich reddish-brown facade that evolves aesthetically over time. Its advanced coating ensures maintenance-free performance, making it a sustainable, modern choice for architectural cladding​.",
      images: [
        "assets/Allproducts/MetaSurface/metacorten/metacorten1.png",
        "assets/Allproducts/MetaSurface/metacorten/metacorten2.png",
        "assets/Allproducts/MetaSurface/metacorten/metacorten4.png",
        "assets/Allproducts/MetaSurface/metacorten/metacorten5.png",
        "assets/Allproducts/MetaSurface/metacorten/metacorten6.jpg",
        "assets/Allproducts/MetaSurface/metacorten/metacorten7.jpg",
      ],
    },
    {
      name: "MetaGrey",
      Productname:"MetaGrey",
      metatittles:"Versatile Grey-Toned Metal Surface for Facades",
      metadescription:"MetaGrey is a premium metal surface available in a range of grayscale finishes, seamlessly blending into modern and minimalist designs.",
      description:
        "A sophisticated grayscale facade system, MetaGrey blends seamlessly with contemporary architecture. Its subtle tones provide visual balance and versatility, making it ideal for projects that require understated elegance with a modern edge​.",
      images: [
        "assets/Allproducts/MetaSurface/metagrey/metagrey1.png",
        "assets/Allproducts/MetaSurface/metagrey/metagrey2.png",
        "assets/Allproducts/MetaSurface/metagrey/metagrey3.png",
        "assets/Allproducts/MetaSurface/metagrey/metagrey4.png",
        "assets/Allproducts/MetaSurface/metagrey/metagrey5.png",
        "assets/Allproducts/MetaSurface/metagrey/metagrey7.png",
        "assets/Allproducts/MetaSurface/metagrey/metagrey8.png",
        "assets/Allproducts/MetaSurface/metagrey/metagrey9.png",
        "assets/Allproducts/MetaSurface/metagrey/metagrey10.png",
        "assets/Allproducts/MetaSurface/metagrey/metagrey6.png",
      ],
    },
    {
      name: "MetaPatina",
      Productname:"MetaPatina",
      metatittles:"Aged Copper & Brass Surface with Verdigris Effect",
      metadescription:"MetaPatina is a treated copper and brass surface that develops a striking verdigris patina, enhancing both durability and architectural charm",
      description:
        " MetaPatina showcases the artistry of oxidized metals, featuring verdigris tints on copper and brass for a luxurious, aged look. With its antimicrobial properties and striking color contrasts, MetaPatina is perfect for interior and exterior applications that demand both beauty and functionality​.",
      images: [
        "assets/Allproducts/MetaSurface/metapatina/metapatina1.png",
        "assets/Allproducts/MetaSurface/metapatina/metapatina2.png",
        "assets/Allproducts/MetaSurface/metapatina/metapatina3.png",
        "assets/Allproducts/MetaSurface/metapatina/metapatina4.png",
        "assets/Allproducts/MetaSurface/metapatina/metapatina5.png",
        "assets/Allproducts/MetaSurface/metapatina/metapatina6.png",
        "assets/Allproducts/MetaSurface/metapatina/metapatina7.png",
        "assets/Allproducts/MetaSurface/metapatina/metapatina8.png",
      ],
    },
    {
      name: "MetaWood",
      Productname:"MetaWood",
      metatittles:"Metal Surface with a Realistic Natural Wood Finish",
      metadescription:"MetaWood offers the warmth of wood with the durability of metal, providing a termite-proof, weather-resistant, and maintenance-free solution.",
      description:
        "A sustainable reimagining of natural wood, MetaWood combines the visual appeal of earthy textures with the strength of metal. Resistant to weather, fire, and termites, it is an environmentally friendly, durable solution for cladding and decorative applications​.",
      images: [
        "assets/Allproducts/MetaSurface/metawood/metawood1.png",
        "assets/Allproducts/MetaSurface/metawood/metawood2.png",
        "assets/Allproducts/MetaSurface/metawood/metawood3.png",
        "assets/Allproducts/MetaSurface/metawood/metawood4.png",
        "assets/Allproducts/MetaSurface/metawood/metawood5.png",
        "assets/Allproducts/MetaSurface/metawood/metawood6.png",
        "assets/Allproducts/MetaSurface/metawood/metawood7.png",
        "assets/Allproducts/MetaSurface/metawood/metawood8.png",
        "assets/Allproducts/MetaSurface/metawood/metawood9.png",
        "assets/Allproducts/MetaSurface/metawood/metawood10.png",
        "assets/Allproducts/MetaSurface/metawood/metawood11.png",
      ],
    },
    {
      name: "SolidPanel",
      Productname:"Solid Panels",
      metatittles:"Durable Architectural Surfaces",
      metadescription:"Our solid metal panels provide a strong, weather-resistant surface available in various finishes, ideal for modern and industrial designs.",
      description:
        "Durable and versatile, Solid Panels are crafted from premium metals for seamless, modern facades. With exceptional weather resistance and low maintenance, they suit both bold designs and intricate architectural projects. Fully customizable in size, finish, and color, Solid Panels blend strength with aesthetic appeal for a variety of applications.",
      images: [
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel1.png",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel2.jpg",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel3.jpg",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel4.jpg",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel5.jpg",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel7.jpg",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel8.jpg",
        "assets/Allproducts/MetaForm/SolidPanel/solidpanel9.jpg",
      ],
    },
  ];

export const ProductImages = [
    {
      imgPath: "assets/Allproducts/MetaParametric/metacoin/cover/metacoin.jpg",
      imgText: "MetaCoin",
    },
    {
      imgPath:
        "assets/Allproducts/MetaParametric/metasequin/cover/metasequin1.jpg",
      imgText: "MetaSequin",
    },
    {
      imgPath: "assets/Allproducts/MetaForm/metapyramid/cover/metapyramid.jpg",
      imgText: "MetaPyramid",
    },
    {
      imgPath: "assets/Allproducts/MetaForm/metashingle/cover/metashingle.png",
      imgText: "MetaShingles",
    },
    {
      imgPath: "assets/Allproducts/MetaForm/CascadingKeys/cover/caskey1.png",
      imgText: "Cascading Keys",
    },
    {
      imgPath:
        "assets/Allproducts/MetaForm/metacassette/cover/metacassette.png",
      imgText: "MetaCassette",
    },
    {
      imgPath: "assets/Allproducts/MetaForm/metaflute/cover/metaflute.png",
      imgText: "MetaFlute",
    },
    {
      imgPath: "assets/Allproducts/MetaFunction/metafold/cover/metafold1.jpg",
      imgText: "MetaFold",
    },
    {
      imgPath: "assets/Allproducts/MetaParametric/metafin/cover/metafin.png",
      imgText: "MetaFin",
    },
    {
      imgPath:
        "assets/Allproducts/MetaParametric/metahydra/cover/metahydra.png",
      imgText: "MetaHydra",
    },
    {
      imgPath: "assets/Allproducts/MetaForm/metalouver/cover/metalouver.png",
      imgText: "MetaLouver",
    },
    {
      imgPath: "assets/Allproducts/MetaForm/metaplank/cover/metaplank.png",
      imgText: "MetaPlank",
    },
    {
      imgPath: "assets/Allproducts/MetaFunction/metagate/cover/metagate4.jpg",
      imgText: "MetaGate",
    },
    {
      imgPath:
        "assets/Allproducts/MetaFunction/metaslider/cover/metaslider.png",
      imgText: "MetaSlider",
    },
    {
      imgPath: "assets/Allproducts/MetaParametric/metablox/cover/metablox.png",
      imgText: "MetaBlox",
    },
    {
      imgPath:
        "assets/Allproducts/MetaSurface/metacopper/cover/metacopper1.png",
      imgText: "MetaCopper",
    },
    {
      imgPath:
        "assets/Allproducts/MetaSurface/metacorten/cover/metacorten3.png",
      imgText: "MetaCorten",
    },
    {
      imgPath: "assets/Allproducts/MetaSurface/metagrey/cover/metagrey.png",
      imgText: "MetaGrey",
    },
    {
      imgPath: "assets/Allproducts/MetaSurface/metapatina/cover/metapatina.png",
      imgText: "MetaPatina",
    },
    {
      imgPath: "assets/Allproducts/MetaSurface/metawood/cover/metawood.png",
      imgText: "MetaWood",
    },
    {
      imgPath: "assets/Allproducts/MetaForm/SolidPanel/cover/solidpanel.jpg",
      imgText: "SolidPanel",
    },
  ];

export const ProjectImages = [
    { imgPath: "assets/Allprojects/commercial/Ardete/cover/ardete5.jpg" },
    { imgPath: "assets/Allprojects/commercial/Tanishq/cover/tanishq11.jpg" },
    { imgPath: "assets/Allprojects/residential/A13-43/cover/a13-431.jpg" },
    { imgPath: "assets/Allprojects/residential/D5-3/cover/ankit1.jpg" },
    { imgPath: "assets/Allprojects/commercial/Gowri/cover/gowri2.jpg" },
    { imgPath: "assets/Allprojects/commercial/Deepak/cover/deepak1.jpg" },
    { imgPath: "assets/Allprojects/residential/A19/cover/a192.jpg" },
    { imgPath: "assets/Allprojects/residential/A18/cover/a181.jpg" },
    { imgPath: "assets/Allprojects/residential/MVA-DL1/cover/varun5.jpg" },
    { imgPath: "assets/Allprojects/commercial/Metaland/cover/metaland6.jpg" },
    { imgPath: "assets/Allprojects/commercial/Micasa/cover/micasa1.jpg" },
    { imgPath: "assets/Allprojects/commercial/yashika/cover/yashika1.jpg" },
    { imgPath: "assets/Allprojects/residential/C76/cover/c7615.jpg" },
    { imgPath: "assets/Allprojects/residential/5107/cover/51071.jpg" },
    { imgPath: "assets/Allprojects/residential/A297/cover/a2971.jpg" },
    // { imgPath: "assets/Allprojects/residential/Chandna/cover/chandna1.jpg" },
    { imgPath: "assets/Allprojects/residential/C34-573/cover/kapil1.jpg" },
    { imgPath: "assets/Allprojects/residential/DLF4/cover/dlf44.jpg" },
    { imgPath: "assets/Allprojects/residential/CS5-MA/cover/vishal1.jpg" },
    { imgPath: "assets/Allprojects/residential/Espace/cover/espace1.jpg" },
    { imgPath: "assets/Allprojects/residential/MA-543/cover/manoj1.jpg" },
    { imgPath: "assets/Allprojects/residential/Q62-RA/cover/tejbir1.jpg" },
    { imgPath: "assets/Allprojects/residential/z1012/cover/z10121.jpg" },
    { imgPath: "assets/Allprojects/residential/Luxe/cover/luxe16.png" },
    { imgPath: "assets/Allprojects/commercial/E10/cover/e105.jpg" },
  ];

  // In your constants.js (or directly in the component)
export const products = [
  {
    id: 1,
    name: "Metacoin",
    
    slug: "metacoin"
  },
  {
    id: 2,
    name: "Metasequin",
    
    slug: "metasequin"
  },
  {
    id: 3,
    name: "Cascading Keys",
    
    slug: "cascading-keys"
  },
  {
    id: 4,
    name: "Metashingles",
    
    slug: "metashingles"
  },
];


